import { render, staticRenderFns } from "./PersistBannerView.vue?vue&type=template&id=995d4982&scoped=true"
import script from "./PersistBannerView.vue?vue&type=script&lang=ts"
export * from "./PersistBannerView.vue?vue&type=script&lang=ts"
import style0 from "./PersistBannerView.vue?vue&type=style&index=0&id=995d4982&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "995d4982",
  null
  
)

export default component.exports