


























































import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator'
import {Banner} from '@/model/resource/Banner'
import {InputBannerSchema} from '@/schema/resource/Banner/InputBannerSchema'
import {InputSelect, InputText} from '@simpli/vue-input'
import {Global, IBannerAction} from '@/model/resource/Global'

@Component({
  components: {InputSelect, InputText},
})
export default class PersistBannerView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputBannerSchema()
  banner = new Banner()

  bannerActions = Global.bannerActions
  bannerAction: IBannerAction | null = null
  isDestinationLink = false

  async created() {
    await this.populateResources()
  }

  async populateResources() {
    const id = Number(this.id) || null

    if (id) {
      await this.banner.getBanner(id)

      this.bannerAction = this.banner.actionAsIBannerAction
      this.onChangeAction()
    }

    this.$await.done('getBanner')
  }

  onChangeAction() {
    this.banner.action = this.bannerAction?.name ?? null
    this.isDestinationLink = this.bannerAction?.name === 'external-link'

    if (!this.isDestinationLink) {
      this.banner.url = ''
    }
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.banner.persistBanner()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/banner/list')
  }
}
